<template>
  <div class="analytics">
    <div class="component-header">
      <span>{{ $route.meta.fullname }}</span>
      <span>
        <a-select default-value="lucy" style="width: 120px">
          <a-select-option value="good">Положительные отзывы</a-select-option>
          <a-select-option value="average">Средние отзывы</a-select-option>
          <a-select-option value="bad">Негативные отзывы</a-select-option>
        </a-select>
      </span>
    </div>
    <div class="analytics-period-widget-wrapper">
      <AnalyticsPeriodWidget
        v-for="item in reviewsData"
        :key="item.name"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsPeriodWidget from "@/components/4laps/AnalyticsPeriodWidget";

export default {
  components: {
    AnalyticsPeriodWidget,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.analytics-period-widget-wrapper {
  display: flex;
  align-items: start;
}
.component-header {
  display: flex;
  align-items: start;
}
</style>
